<template>
  <b-modal
    id="modal-editsurvey"
    v-model="show"
    :title="title"
    :size="showAnswers ? 'xl' : 'lg'"
    centered
    hide-footer
    @hidden="resetModal"
    no-close-on-backdrop="true"
  >
    <b-overlay :show="loading">
      <form>
        <b-row>
          <b-col cols="5" :class="{ 'border-right': showAnswers }">
            <b-row>
              <b-col>
                <basic-input
                  label="Mã câu hỏi"
                  required
                  name="code"
                  data-vv-as="Mã câu hỏi"
                  class="score-input"
                  :value.sync="this.question.code"
                  :disabled="true"
                >
                </basic-input>
              </b-col>
            </b-row>
            <b-row>
              <div style="margin-left: 10px">Tiêu đề câu hỏi</div>
            </b-row>
            <textarea
              id="textarea"
              label="Tiêu đề câu hỏi"
              required
              name="name"
              :value.sync="this.question.name"
              :state="validateState(`name`)"
              :invalid-feedback="errors.first(`name`)"
              data-vv-as="Tiêu đề câu hỏi"
              v-validate="'required|max:500'"
              disabled="true"
              maxlength="500"
            ></textarea>

            <basic-select
              required
              label="Loại câu hỏi"
              placeholder="--- Chọn loại câu hỏi ---"
              name="type"
              :solid="false"
              :allowEmpty="false"
              value-label="text"
              track-by="value"
              :options="questionTypes"
              :value.sync="form.type"
              :state="validateState(`type`)"
              :invalid-feedback="errors.first(`type`)"
              data-vv-as="Tiêu đề câu hỏi"
              v-validate="'required'"
              :disabled="isDetail || !!form.source || isRelatedQuestion"
              @update:value="selectType"
            />
       
            <basic-input
              required
              label="Câu trả lời của khách hàng"
              placeholder="--- Đáp án ---"
              name="related-answer"
              :solid="false"
              :allowEmpty="false"
              value-label="content"
              track-by="id"
              :value.sync="form.selfAnswer"
              data-vv-as="Đáp án"
              v-b-tooltip="'Chỉ dành cho câu hỏi dạng Text '"

            />
          </b-col>

          <b-col cols="7">
            <div class="font-weight-bolder mb-2">
              Định nghĩa danh sách câu trả lời
            </div>
            <v-data-table
              v-if="!isRating"
              :headers="headers"
              :items="this.question.answers"
              id="sortable"
              hide-default-footer
              ref="sortableTable"
              :items-per-page="-1"
               v-b-tooltip="'Chọn một câu trả lời'"

            >
              <template v-slot:item="{ item }">
                <tr>
                  <td>{{ item.content }}</td>

                  <td>
                    <b-form-radio v-model="selectedId" :value="item.id">
                    </b-form-radio>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </b-col>
        </b-row>
        <div class="text-right">
          <b-button
            class="btn btn-success ml-3"
            type="button"
            @click="UpdateAnswer()"
            :disabled="this.isDisable"
          >
            <span class="svg-icon">
              <inline-svg src="/media/svg/icons/Neolex/Basic/save.svg" />
            </span>
            Lưu
          </b-button>
        </div>
      </form>
    </b-overlay>
  </b-modal>
</template>
<script>
import { MODAL_TYPE } from '@/core/plugins/constants';
import { createNamespacedHelpers } from 'vuex';
import { EDIT_QUESTION_GROUP } from '@/core/services/store/survey.module';
import { MODAL_STATUS } from '@/core/plugins/constants';
// import axios from 'axios';
const { mapActions, mapState } = createNamespacedHelpers('survey');
export default {
  name: 'ModalEditSurvey',
  components: {},
  props: {
    type: {
      type: String,
      default: MODAL_TYPE.CREATE,
    },
    id: {
      type: String,
      default: null,
    },
    question: {
      type: Object,
      default: () => {},
    },
    patientId: {
      type: String,
      default: null,
    },
    section: {
      type: Object,
      default: null,
    },
    accountId: {
      type: String,
      default: null,
    },
    ownPackage: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      selectedId: null,

      form: {
        isScore: false,
        isRelatedQuestions: false,
        isRelatedPatients: false,
        type: {
          text: 'Single choice',
          value: 1,
        },
        code: null,
        name: null,
        order: null,
        relatedQuestion: null,
        relatedAnswer: null,
        source: null,
        selfAnswer: null,
      },
      answers: [],
      answer: {
        isAddNew: true,
        name: null,
        score: null,
      },
      itemKeys: new WeakMap(),
      currentItemKey: 0,
      questionTypes: [
        {
          text: 'Single choice',
          value: 1,
        },
        {
          text: 'Multiple choices',
          value: 2,
        },
        {
          text: 'Text',
          value: 3,
        },
        {
          text: 'Rating',
          value: 4,
        },
      ],
      flags: Array.from({ length: 11 }, (_, i) => {
        return {
          text: `${i}`,
          value: `${i}`,
        };
      }),
      loading: false,
      orders: [],
      isRequireAnswer: false,
      isRatingRequired: false,
      isRequireScore: false,
      ratingAnswers: [],
      deletedAnswer: [],
      validCode: false,
      showErrorAnswers: false,
      showModalQuizeAvailable: false,
      answerLength: 26,
      typeAnswer: null,
      surveyAnswerId: null,
      content: 'string',
      surveyResultId: null,
      isDisable: false,
    };
  },

  computed: {
    ...mapState(['questions', 'lastOrder']),
    title() {
      switch (this.type) {
        case MODAL_TYPE.CREATE:
          return 'Tạo câu hỏi';
        case MODAL_TYPE.EDIT:
          return 'Chỉnh sửa câu hỏi';

        default:
          return 'Xem chi tiết câu hỏi';
      }
    },
    show: {
      get() {
        return this.$store.state.context.modalShow;
      },
      set(val) {
        this.$store.commit(`context/setModal`, val);
      },
    },
    headers() {
      if (!this.isDetail && !this.form.source) {
        return [
          {
            text: '',
            value: 'actionMove',
            sortable: false,
            width: '1%',
          },
          {
            text: '',
            value: 'radioBtn',
            sortable: false,
            width: '1%',
          },

          {
            text: 'Câu trả lời',
            value: 'answer',
            sortable: false,
          },

          {
            text: '',
            value: 'action',
            sortable: false,
            width: '1%',
          },
        ];
      }
      return [
        {
          text: 'Câu trả lời',
          value: 'answer',
          sortable: false,
        },
        {
          text: 'Chọn',
          value: 'score',
          sortable: false,
        },
      ];
    },
    showAnswers() {
      if (this.form.type && this.form.type.value !== 3) return true;
      return false;
    },
    isEdit() {
      return this.type === MODAL_TYPE.EDIT;
    },
    isDetail() {
      return this.type === MODAL_TYPE.DETAIL;
    },
    isCreate() {
      return this.type === MODAL_TYPE.CREATE;
    },
    answersOfQuestion() {
      if (!this.form.relatedQuestion) return [];
      return this.form.relatedQuestion.answers;
    },
    relatedQuestions() {
      if (!this.isEdit) return this.questions;
      return [...this.questions].filter((el) => el.order < this.question.order);
    },
    isRelatedQuestion() {
      if (!this.questions.length || !this.question) return false;
      const reletedQuestion = this.questions.find(
        (el) => el.mappedQuestionId === this.question.id,
      );
      return reletedQuestion ? true : false;
    },
  },
  watch: {
    orders: {
      handler(val) {
        if (this.isCreate) {
          this.form.order = this.selectDropdown(this.lastOrder, val);
        }
      },
      deep: true,
    },
    isRating(val) {
      if (val) {
        this.answer.flag = null;
        !this.isDetail && this.ratingAnswers.push(this.answer);
      } else {
        delete this.answer.flag;
        this.ratingAnswers = [];
      }
    },
    'form.code': function (val) {
      let code = null;
      if (this.question?.code) {
        code = this.question.code;
      }
      if (!val || code === val) return (this.validCode = false);
      this.checkDuplicateCode(val);
    },
  },
  mounted() {
   this.setupData();
   
 
  },
  methods: {
    ...mapActions({ EDIT_QUESTION_GROUP }),
    setupData() {
      if (!this.isCreate) {
     //   this.form.selfAnswer = this.chooseAnswer();
        this.form.code = this.question.code;
        this.form.name = this.question.name;
        this.form.type = this.questionTypes.find(
         (el) => el.value === this.question.type,
         );

      }
  
  
    },
    resetModal() {
      this.$store.commit(`context/setModal`, false);
      this.$emit('reset');
    },
    cancelModal() {
      this.$store.commit(`context/setModal`, false);
    },

    itemKey(item) {
      if (!this.itemKeys.has(item))
        this.itemKeys.set(item, ++this.currentItemKey);
      return this.itemKeys.get(item);
    },

    selectDropdown(value, array) {
      let selected = null;
      if (value) {
        selected = array.find((el) => el.value === value);
      } else {
        selected = null;
      }
      return selected;
    },
    validateState(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.errors.has(ref);
      }
      return null;
    },

    submit() {
      const params = {
        name: this.form.name,
        code: this.form.code,
        order: this.form.order.value,
        type: this.form.type.value,
        isScore: this.form.isScore,
        isRelatedQuestions: this.form.isRelatedQuestions,
        isRelatedPatients: this.form.isRelatedPatients,
        mappedQuestionId: this.form.relatedQuestion?.id,
        mappedAnswerId: this.form.relatedAnswer?.id,
        answers: this.getAnswers(),
      };

      if (this.isCreate) {
        params['modelStatus'] = MODAL_STATUS.NEW;
        params['answers'] = this.getAnswerParams([], params.answers);
        return this.updateQuestion(params);
      }
      params['id'] = this.question.id;
      params['modelStatus'] = this.question.modelStatus;

      params['answers'] = this.getAnswerParams(
        this.question.answers,
        params.answers,
      );

      const result = this.isEqualTwoObjects(
        this.removeEmpty(this.question),
        this.removeEmpty(params),
      );
      if (
        !result ||
        params.answers.filter((el) => el.modelStatus !== MODAL_STATUS.UNCHANGE)
          .length
      ) {
        params['modelStatus'] = MODAL_STATUS.CHANGED;
      }

      this.updateQuestion(params);
    },
    getAnswerParams(defaultItems, beforeSubmitItems) {
      let answers = [];

      const answerParamsPush = (array, status, type = 1) => {
        array.forEach((answer) => {
          let params = {
            ...answer,
            id: type ? answer.id : null,
            modelStatus: status,
          };
          answers.push(this.removeEmpty(params));
        });
      };

      let [commonItems, deletedItems, addNewItems] = this.getItemWithStatus(
        defaultItems,
        beforeSubmitItems,
      );
      let changedItems = this.getChangedItems(defaultItems, beforeSubmitItems);

      // if (changedItems.length) {
      //   commonItems = differenceBy(commonItems, changedItems, 'id');
      // }
      const params = [
        {
          value: changedItems,
          status: MODAL_STATUS.CHANGED,
          type: 1,
        },
        {
          value: commonItems,
          status: MODAL_STATUS.UNCHANGE,
          type: 1,
        },
        {
          value: deletedItems,
          status: MODAL_STATUS.DELETE,
          type: 1,
        },
        {
          value: addNewItems,
          status: MODAL_STATUS.NEW,
          type: 0,
        },
      ];

      params.forEach((el) => {
        answerParamsPush(el.value, el.status, el.type);
      });
      return answers;
    },

    selectedItem(id, array) {
      return array.find((el) => el.id === id);
    },
    selectedQuestion() {
      this.form.relatedAnswer = null;
      this.$validator.reset({ name: `realted-question` });
    },
    chooseAnswer() {
      if (this.question.type == '3') {
        return this.question.result[0].content;
      } else {
        return this.question.result[0].answerName;
      }
    },

    chooseAnswerId() {
      if (this.question.type == '3') {
        return;
      } else {
        var ListAnswerId = [this.selectedId];
        return ListAnswerId;
      }
    },

    chooseContent() {
      if (this.question.type == '3') {
        return this.form.selfAnswer;
      } else {
        return null;
      }
    },

    async UpdateAnswer() {

      this.isDisable = true;
    await this.$api
        .put('/SurveyResult', {
          AccountId: this.accountId,
          SurveyQuestionId: this.question.id,
          SurveyAnswerIdList: this.chooseAnswerId(),
          SurveySectionId: this.section.id,
          Content: this.chooseContent(),
          PackageAccountTransactionId: this.ownPackage,
        })
        .then(() => {
          this.$toastr.s({
            title: 'Thành công!',
            msg: 'Cập nhật thành công',
          });
          this.$root.$emit('onShowForm-event');
        })
        .catch(() => {
          this.$toastr.e({
            title: 'Lỗi!',
            msg: 'Đã có lỗi xảy ra',
          });
        })
        .finally(() => {
          this.$bvModal.hide('modal-editsurvey');
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.handle {
  cursor: move !important;
  cursor: -webkit-grabbing !important;
}
.btn-save {
  color: #008479 !important;
  background-color: #def2f1 !important;
  border-color: #def2f1 !important;
}
</style>

<style lang="scss">
.score-input {
  .form-group {
    margin-bottom: 0 !important;
  }
}

#modal-editsurvey {
  .modal-dialog {
    max-width: 50%;
  }
}
#textarea {
  word-wrap: break-word;
  width: 450px;
  margin-top: 20px;
  background-color: #f8fafb;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #b3b3b3;
  padding: 5px;
  //  height:300px
}
</style>
